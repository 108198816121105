.app-container {
  display: flex;
  min-height: 100vh;
  /* Ensures container fills viewport height */
  flex-direction: column;
}

.app-container>div:not(.footer) {
  /* Targets all children except footer */
  padding-bottom: 20px;
}

.app-content {
  display: flex;
  flex-direction: column;
  /* Align items vertically */
  min-height: 100vh;
  /* Ensure content takes up full viewport height */
  paddingBottom: 80px;
  /* Adjust this value to match footer height */
}

.footer {
  /* Existing footer styles */
  /* ... any existing footer styles ... */
  width: 100%;
}